<template>
  <div class="row_contain" style="padding-top: 46px">
    <van-nav-bar
      :fixed="fixed"
      title="修改密码"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="password"
        name="pas"
        label="密码"
        placeholder="请填写新密码"
        :rules="[{ required: true, message: '请填写新密码' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>

export default {
  data () {
    return {
      fixed: true,
      password: ''
    }
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    async onSubmit (values) {

      console.log('submit', values);
      this.$Toast.loading({ forbidClick: true });

      let res = await this.$http.get('user/edit_pwd', {
        params: {
          user_id: this.$store.state.login.userInfo.user_id,
          psd: values.pas
        }
      });

      if (res.data.error_code == 0) {

        this.$Toast.clear();
        this.$Notify('密码修改成功,请重新登录^-^')
        this.$store.dispatch('clearLoginAsync');
        setTimeout(() => {
          this.$router.replace('/')
        }, 800)
      }


    }
  }
}
</script>

<style>
</style>